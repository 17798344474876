<template>
  <div class="locale-changer" v-if="selectedLocale">
    <b-list-group>
      <b-list-group-item class="bg-light">
        <h4 class="m-0 text-secondary">Select language</h4>
      </b-list-group-item>
      <b-list-group-item
        v-for="(lang, i) in langs"
        v-bind:class="{ '': true, 'bg-light': lang == selectedLocale }"
        :key="`Lang${i}`"
        @click="localeChange(lang)"
      >
        <img
          :src="require(`@/assets/images/flags/${lang.value}.svg`)"
          :alt="lang.label"
          class="flag float-left"
        />
        <p class="font-weight-bold m-0">{{ lang.label }}</p>
        <p class="m-0">{{ lang.labelAlt }}</p>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "locale-changer-list",
  data() {
    return {
      selectedLocale: null,
      langs: [
        {
          label: "English",
          labelAlt: "United Kingdom",
          value: "en"
        },
        {
          label: "French",
          labelAlt: "Française",
          value: "fr"
        },
        {
          label: "Germany",
          labelAlt: "Deutsch",
          value: "de"
        },
        {
          label: "Dutch",
          labelAlt: "Néerlandaise",
          value: "nl"
        },
        {
          label: "Polish",
          labelAlt: "Polonais",
          value: "pl"
        }
      ]
    };
  },
  mounted() {
    let storedLocale = JSON.parse(localStorage.getItem("locale"));
    this.selectedLocale = storedLocale || this.langs[0];
    document.body.classList.add(this.selectedLocale.value);
  },
  methods: {
    localeChange(locale) {
      document.body.classList.remove(this.selectedLocale.value);
      this.$i18n.locale = locale.value;
      this.selectedLocale = locale;
      document.body.classList.add(this.selectedLocale.value);
      localStorage.setItem("locale", JSON.stringify(this.selectedLocale));
    }
  }
};
</script>

<style lang="scss" scoped>
.flag {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>

<style lang="scss">
@import "../assets/scss/core/_variables";

.locale-changer {
  .dropdown-toggle {
    text-align: left;
    width: 200px;
    border: 1px solid $brand-secondary;

    &:after {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  .dropdown-menu {
    width: 200px;

    .dropdown-item {
      padding: 0.25rem 1rem;
    }
  }
}
</style>
