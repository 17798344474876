<template>
  <b-modal id="specs" size="lg" centered hide-footer>
    <b-container>
      <b-row>
        <b-col cols="12" lg="4">
          <h6>{{ $t("lang.specs.column_1.title") }}</h6>
          <div v-html="$t('lang.specs.column_1.content')"></div>
        </b-col>
        <b-col cols="12" lg="4">
          <h6>{{ $t("lang.specs.column_2.title") }}</h6>
          <div v-html="$t('lang.specs.column_2.content')"></div>
        </b-col>
        <b-col cols="12" lg="4">
          <p>{{ $t("lang.specs.column_3.content") }}</p>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  name: "Specs"
};
</script>
<style lang="scss">
#specs.modal {
  ul {
    padding: 0 0 0 17px;

    li {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
    padding: 32px 0 0;
  }
}
</style>
