export default {
  general: {
    brand_text: "Innovation for a Better Life",
    privacy: "Privacy",
    cookies: "Cookies",
    direct_marketing: "Direct Marketings",
    transfer_outside: "Transfer Outside EEA",
    copyright: "Copyright © 2021 {companyName}. All Rights Reserved.",
    powered_by:
      'This Virtual Event is powered by&nbsp;<a href="https://www.reydar.com/ar-solutions/virtual-events/" target="_blank">REYDAR</a>',
    cookie_popup: {
      title: "LG.COM Cookies",
      content:
        "We use cookies to offer you a convenient web browsing experience, to suggest personalized ads for you, and to keep improving functions through traffic analysis.\n" +
        "By clicking on ‘ACCEPT ALL’, you consent to our use of cookies. To find out more, please read our Privacy Policy.",
      settings: {
        button: {
          label: "Cookie Settings"
        }
      },
      accept: {
        button: {
          label: "Accept All"
        }
      }
    }
  },
  home: {
    cta: {
      content: "With LG Air Solution your Loyal Guide",
      button: {
        label: "Free entry to ISH Digital"
      }
    },
    column1: {
      title: "LG Air Solution Virtual Booth",
      content:
        "This virtual event is a highly immersive 3D experience. For optimal performance, we advise visiting from a computer that meets the recommended specs.",
      button: {
        label: "Recommended specs"
      }
    },
    promo: {
      button: {
        label: "Enter virtual event"
      }
    }
  },
  specs: {
    column_1: {
      title: "Recommended System Specs:",
      content:
        "<ul><li>64-bit operating system</li><li>8GB of RAM or higher</li><li>Dedicated NVIDIA/AMD GPU (Geforce 400 or Radeon HD 7000 Series or higher)</li><li>Quad-core Intel/AMD CPU (Intel Sandy Bridge or AMD Bulldozer Series or higher)</li><li>Internet Speed - 10mb Download</li></ul>"
    },
    column_2: {
      title: "Minimum System Specs:",
      content:
        "<ul><li>64-bit operating system</li><li>8GB of RAM or higher</li><li>Dedicated NVIDIA/AMD GPU (Geforce 400 or Radeon HD 7000 Series or higher)</li><li>Quad-core Intel/AMD CPU (Intel Sandy Bridge or AMD Bulldozer Series or higher)</li><li>Internet Speed - 10mb Download</li></ul>"
    },
    column_3: {
      title: "",
      content:
        "If your computer doesn’t meet the minimum spec, unfortunately you won’t be able to view this experience."
    }
  }
};
