<template>
  <div class="layout text-left font-dark-grey">
    <Header />
    <slot />
    <section class="footer-wrapper">
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/core/_variables";

.footer-wrapper {
  padding: 20px 0;
  background-color: $brand-secondary;
}
.icon {
  margin: 0 0 20px;
  width: 55px;
}
.icon-sm {
  float: left;
  margin: 4px 7.5px 0 0;
  height: 20px;
}
</style>
