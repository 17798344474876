<template>
  <div class="unity-bg">
    <Unity />
  </div>
</template>

<script>
import Unity from "@/components/Unity";

export default {
  name: "Home",
  components: {
    Unity
  },
  methods: {
    startCallBack: function(x) {
      console.log(x);
    },
    endCallBack: function(x) {
      console.log(x);
    }
  }
};
</script>

<style lang="scss" scoped>
.unity-bg {
  height: calc(100vh - 96.77px - 88px);
  overflow: hidden;
}
</style>
