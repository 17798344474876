<template>
  <div class="header">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="/"
        ><img
          :src="require('@/assets/images/logo.svg')"
          alt="LG Business Solutions"
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <p class="font-weight-bold m-0">
            {{ $t("lang.general.brand_text") }}
          </p>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {}
};
</script>

<style lang="scss">
@import "../assets/scss/core/_variables";

.header {
  background-color: $brand-secondary;
}

.navbar-toggler {
  .navbar-toggler-icon {
    &:before,
    &:after {
      background-color: $dark-grey !important;
    }
  }
}

.navbar-collapse {
  &.show {
    background: $white !important;

    z-index: 999;
  }
}
</style>
