import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import VueI18n from "vue-i18n";
import langEn from "@/lang/en/lang";
import langFr from "@/lang/fr/lang";
import langDe from "@/lang/de/lang";
import langNl from "@/lang/nl/lang";
import langPl from "@/lang/pl/lang";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentFallbackWarn: false,
  messages: {
    en: {
      lang: langEn
    },
    fr: {
      lang: langFr
    },
    de: {
      lang: langDe
    },
    nl: {
      lang: langNl
    },
    pl: {
      lang: langPl
    }
  }
});
import { BootstrapVue } from "bootstrap-vue";
import "@/assets/scss/app.scss";

Vue.use(BootstrapVue);

new Vue({
  methods: {
    showModal() {
      if (this.$refs["modal-cookies"] !== undefined) {
        setTimeout(() => {
          this.$refs["modal-cookies"].show();
        }, 5000);
      }
    }
  },
  mounted() {
    this.showModal();
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
