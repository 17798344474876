export default {
  tld: "DE",
  ish: "DG",
  general: {
    privacy: "Datenschutzerklärung",
    direct_marketing: "Direct Marketings",
    transfer_outside: "Datenübermittlung außerhalb des EWR",
    copyright: "Copyright © 2021 {companyName}. All Rights Reserved.",
    powered_by:
      'This Virtual Event is powered by&nbsp;<a href="https://www.reydar.com/ar-solutions/virtual-events/" target="_blank">REYDAR</a>',
    cookie_popup: {
      title: "LG.COM Cookies",
      content:
        "We gebruiken cookies om u een comfortabele internetervaring te bieden, om gepersonaliseerde advertenties aan te bieden en om de functies van de website te blijven verbeteren door middel van analyse van het gebruikersverkeer.\n" +
        "Door te klikken op ALLES ACCEPTEREN gaat u akkoord met ons gebruik van cookies. Voor meer informatie kunt u ons privacy en cookiebeleid lezen.",
      settings: {
        button: {
          label: "Cookie-Einstellungen"
        }
      },
      accept: {
        button: {
          label: "Akzeptiere alle"
        }
      }
    }
  },
  home: {
    cta: {
      content: "mit LG Air Solution. Dein Loyaler Gefährte.",
      button: {
        label: "Sichern Sie sich den kostenlosen Zugang zu der ISH Digital"
      }
    },
    column1: {
      title: "LG Air Solution Virtueller Stand",
      content:
        "Dieses virtuelle Ereignis ist ein sehr eindringliches 3D-Erlebnis. Für eine optimale Leistung empfehlen wir den Besuch von einem Computer, der die empfohlenen Spezifikationen erfüllt.",
      button: {
        label: "Empfohlene Spezifikationen"
      }
    },
    promo: {
      button: {
        label: "Virtuelles Ereignis eingeben"
      }
    }
  },
  specs: {
    column_1: {
      title: "Empfohlene Systemspezifikationen:",
      content:
        "<ul><li>64-bit-Betriebssystem</li><li>8 GB RAM oder höher</li><li>Dedizierte NVIDIA/AMD GPU (Geforce 400 oder Radeon-HD-7000-Serie oder höher)</li><li>Quad-core Intel/AMD CPU (Intel Sandy Bridge oder AMD Bulldozer-Serie oder höher)</li><li>Internet-Geschwindigkeit - 10-MB-Download</li></ul>"
    },
    column_2: {
      title: "Minimale Systemspezifikationen:",
      content:
        "<ul><li>64-bit-Betriebssystem</li><li>8 GB RAM oder höher</li><li>Dedizierte NVIDIA/AMD GPU (Geforce 400 oder Radeon-HD-7000-Serie oder höher)</li><li>Quad-core Intel/AMD CPU (Intel Sandy Bridge oder AMD Bulldozer-Serie oder höher)</li><li>Internet-Geschwindigkeit - 10-MB-Download</li></ul>"
    },
    column_3: {
      title: "",
      content:
        "Wenn Ihr Computer die Mindestspezifikation nicht erfüllt, können Sie diese Erfahrung leider nicht anzeigen."
    }
  }
};
