<template>
  <div>
    <b-container class="my-5">
      <b-row class="justify-content-center">
        <b-col cols="10" lg="4">
          <LocaleChangerList />
        </b-col>
        <b-col cols="10" lg="8">
          <div
            class="promo d-flex"
            style="background: require('@/assets/images/banner.jpg') no-repeat center / cover;"
          >
            <b-button
              variant="primary"
              href="/scene"
              class="m-auto"
              v-if="supported"
              >{{ $t("lang.home.promo.button.label") }}</b-button
            >
            <p v-if="!supported">Not supported text goes here</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="two-column">
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12" lg="10" class="mb-5 mb-lg-0">
            <h3 class="mb-4">{{ $t("lang.home.column1.title") }}</h3>
            <p>{{ $t("lang.home.column1.content") }}</p>
            <b-button variant="primary" v-b-modal.specs class="mt-auto">
              {{ $t("lang.home.column1.button.label") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Specs />
  </div>
</template>

<script>
import LocaleChangerList from "@/components/LocaleChangerList";
import Specs from "@/components/Specs";

const Bowser = require("bowser");
const currentBrowser = Bowser.getParser(window.navigator.userAgent);

export default {
  name: "Home",
  components: {
    LocaleChangerList,
    Specs
  },
  data: () => ({
    supported: true,
    browser: "",
    browserVersion: "",
    operatingSystem: ""
  }),
  methods: {
    startCallBack: function(x) {
      console.log(x);
    },
    endCallBack: function(x) {
      console.log(x);
    }
  },
  created() {
    this.browser = currentBrowser.getBrowserName();
    this.browserVersion = currentBrowser.getBrowserVersion();
    this.operatingSystem = currentBrowser.getOSName();
    if (
      this.operatingSystem === "iOS" ||
      this.operatingSystem === "iPadOS" ||
      this.operatingSystem === "Android"
    ) {
      this.supported = false;
    } else if (this.browser === "Firefox") {
      if (this.operatingSystem === "Windows") {
        this.supported = false;
      }
    } else if (this.browser === "Microsoft Edge") {
      if (this.browserVersion >= "83") {
        this.supported = true;
      } else {
        this.supported = false;
      }
    }
  }
};
</script>
<style lang="scss">
.two-column {
  padding: 5rem 0;
  background-color: #f2f2f2;
}

.promo {
  background: url("/banner.jpg") no-repeat center / cover;
  min-height: 415px;
}
</style>
