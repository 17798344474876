export default {
  tld: "FR",
  ish: "FR",
  general: {
    privacy: "Politique de confidentialité",
    direct_marketing: "Marketing Direct",
    transfer_outside: "Transfert en dehors de L'EEE",
    copyright: "Copyright © 2021 {companyName}. All Rights Reserved.",
    powered_by:
      'This Virtual Event is powered by&nbsp;<a href="https://www.reydar.com/ar-solutions/virtual-events/" target="_blank">REYDAR</a>',
    cookie_popup: {
      title: "LG.COM Cookies",
      content:
        "Wir verwenden Cookies, um Ihnen ein angenehmes Surferlebnis im Internet zu bieten, Ihnen personalisierte Anzeigen vorschlagen zu können und die Nutzung durch Traffic-Analysen stetig zu verbessern. Wenn Sie auf „ALLE AKZEPTIEREN“ klicken, stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie in unseren Datenschutzbestimmungen.",
      settings: {
        button: {
          label: "Paramètres des cookies"
        }
      },
      accept: {
        button: {
          label: "Aaccepter tout"
        }
      }
    }
  },
  home: {
    cta: {
      content: "avec votre Loyal Guide LG Air Solution",
      button: {
        label: "Entrée gratuite au salon digital ISH"
      }
    },
    column1: {
      title: "Stand virtuel LG Air Solution",
      content:
        "Cet événement virtuel est une expérience 3D hautement immersive. Pour des performances optimales, nous vous conseillons d’utiliser un ordinateur qui répond aux spécifications recommandées.",
      button: {
        label: "Spécifications recommandées"
      }
    },
    promo: {
      button: {
        label: "Entrer dans l'événement virtuel"
      }
    }
  },
  specs: {
    column_1: {
      title: "Spécifications Système Recommandées:",
      content:
        "<ul><li> Système d'exploitation 64 bits</li><li>8 Go de RAM ou plus</li><li>GPU dédié NVIDIA / AMD (Geforce 400 ou Radeon HD 7000 Series ou supérieur)</li><li>Processeur Intel/AMD quad-core (série Intel Sandy Bridge ou AMD Bulldozer ou supérieure)</li><li>Vitesse Internet-10 Mo de téléchargement</li></ul>"
    },
    column_2: {
      title: "Spécifications Minimales Du Système:",
      content:
        "<ul><li>Système d'exploitation 64 bits</li><li>8 Go de RAM ou plus</li><li>GPU dédié NVIDIA / AMD (Geforce 400 ou Radeon HD 7000 Series ou supérieur)</li><li>Processeur Intel/AMD quad-core (série Intel Sandy Bridge ou AMD Bulldozer ou supérieure)</li><li>Vitesse Internet-10 Mo de téléchargement</li></ul>"
    },
    column_3: {
      title: "",
      content:
        "Si votre ordinateur ne répond pas aux spécifications minimales, vous ne pourrez malheureusement pas visualiser cette expérience."
    }
  }
};
