<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div
          id="unity-container"
          class="unity-desktop text-center"
          ref="myInstance"
        >
          <canvas id="unity-canvas"></canvas>
          <div id="unity-loading-bar">
            <div id="unity-logo"></div>
            <div id="unity-progress-bar-empty">
              <div id="unity-progress-bar-full"></div>
            </div>
          </div>
          <div id="unity-footer" class="px-5">
            <div id="unity-fullscreen-button" class="d-none"></div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: "Unity",
  methods: {
    ChangeLanguage() {
      let storedLocale = JSON.parse(localStorage.getItem("locale"));
      this.selectedLocale = storedLocale || this.langs[0];
      console.log(this.selectedLocale);
    }
  },
  data: () => ({
    langs: [
      {
        label: "English",
        labelAlt: "United Kingdom",
        value: "en"
      },
      {
        label: "French",
        labelAlt: "Française",
        value: "fr"
      },
      {
        label: "Germany",
        labelAlt: "Deutsch",
        value: "de"
      },
      {
        label: "Dutch",
        labelAlt: "Néerlandaise",
        value: "nl"
      },
      {
        label: "Polish",
        labelAlt: "Polonais",
        value: "pl"
      }
    ]
  }),
  mounted() {
    let storedLocale = JSON.parse(localStorage.getItem("locale"));
    this.selectedLocale = storedLocale || this.langs[0];
    var buildUrl = "unity/Build";
    var loaderUrl = buildUrl + "/LG-WebGL-v4.loader.js";
    var config = {
      dataUrl: buildUrl + "/LG-WebGL-v4.data",
      frameworkUrl: buildUrl + "/LG-WebGL-v4.framework.js",
      codeUrl: buildUrl + "/LG-WebGL-v4.wasm",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "OllieJones.com",
      productName: "VirtualEvent-LG",
      productVersion: "0.3"
    };

    var container = document.querySelector("#unity-container");
    var canvas = document.querySelector("#unity-canvas");
    var loadingBar = document.querySelector("#unity-loading-bar");
    var progressBarFull = document.querySelector("#unity-progress-bar-full");
    var fullscreenButton = document.querySelector("#unity-fullscreen-button");

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      container.className = "unity-mobile";
      config.devicePixelRatio = 1;
    } else {
      canvas.style.width = "1920px";
      canvas.style.height = "1080px";
    }
    loadingBar.style.display = "block";

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
      // eslint-disable-next-line no-undef
      createUnityInstance(canvas, config, progress => {
        progressBarFull.style.width = 100 * progress + "%";
      })
        .then(unityInstance => {
          loadingBar.style.display = "none";
          fullscreenButton.onclick = () => {
            unityInstance.SetFullscreen(1);
          };
          unityInstance.SendMessage(
            "JSBridge",
            "OnLanguageSet",
            this.selectedLocale.value
          );
        })
        .catch(message => {
          alert(message);
        });
    };
    document.body.appendChild(script);
  }
};
</script>
<style>
.unity-bg {
  background: #393939;
}
#unity-progress-bar-empty {
  margin: 40px auto 0 !important;
}
#unity-logo {
  background: url(/logo-white.svg) no-repeat !important;
  background-size: contain !important;
  height: 68px !important;
  width: 300px !important;
}
#unity-canvas {
  height: inherit !important;
  width: 100% !important;
  display: block;
}
#unity-container {
  position: inherit !important;
}
#unity-container.unity-desktop {
  left: inherit !important;
  top: inherit !important;
  transform: inherit !important;
}
</style>
