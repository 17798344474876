export default {
  tld: "PL",
  ish: "PL",
  general: {
    privacy:
      "Aby zapoznać się z pełną Polityką Prywatności, kliknij poniższe linki",
    direct_marketing: "Polityka Prywatności / Marketing bezpośredni",
    transfer_outside: "Transfer poza EOG",
    copyright: "Copyright © 2021 {companyName}. All Rights Reserved.",
    powered_by:
      'This Virtual Event is powered by&nbsp;<a href="https://www.reydar.com/ar-solutions/virtual-events/" target="_blank" class="font-weight-bold">REYDAR</a>',
    cookie_popup: {
      title: "LG.COM Cookies",
      content:
        'Ta strona wykorzystuje pliki Cookies (Ciasteczka), które są ważne dla prawidłowego funkcjonowania serwisu oraz do optymalizacji przeglądania, personalizacji zawartości zgodnie z Państwa zainteresowaniami oraz w celu analizowania ruchu na witrynie.Klikając "AKCEPTUJ WSZYSTKIE PLIKI COOKIE" zgadzasz się na używanie przez nas plików Cookie.Więcej informacji znajduje się w naszej Polityce Prywatności - Ochrony Danych Osobowych',
      settings: {
        button: {
          label: "Ustawienia plików cookies"
        }
      },
      accept: {
        button: {
          label: "Akceptuj wszystkie"
        }
      }
    }
  },
  home: {
    cta: {
      content: "z LG Air Solution Loyal Guide",
      button: {
        label: "Darmowe bilety na Targi ISH Digital"
      }
    },
    column1: {
      title: "Wirtualne Stoisko LG Air Solution",
      content:
        "To wirtualne wydarzenie to niezwykle wciągające doświadczenie 3D. Aby uzyskać optymalną wydajność, zalecamy wizytę z komputera, który spełnia zalecane specyfikacje.",
      button: {
        label: "Zalecane specyfikacje"
      }
    },
    promo: {
      button: {
        label: "Wejdź na wydarzenie wirtualne"
      }
    }
  },
  specs: {
    column_1: {
      title: "Zalecane Specyfikacje Systemu:",
      content:
        "<ul><li>64-bitowy system operacyjny</li><li> 8 GB PAMIĘCI RAM lub wyższej</li><li>Dedykowany układ GPU NVIDIA / AMD (Geforce 400 lub Radeon HD 7000 Series lub wyższy)</li><li>Czterordzeniowy procesor Intel/AMD (Seria Intel Sandy Bridge lub AMD Bulldozer lub wyższa)</li><li>Prędkość internetu-Ściąganie 10mb</li></ul>"
    },
    column_2: {
      title: "Minimalne Specyfikacje Systemu:",
      content:
        "<ul><li>64-bitowy system operacyjny</li><li>8 GB PAMIĘCI RAM lub wyższej</li><li>Dedykowany układ GPU NVIDIA / AMD (Geforce 400 lub Radeon HD 7000 Series lub wyższy)</li><li>Czterordzeniowy procesor Intel/AMD (Seria Intel Sandy Bridge lub AMD Bulldozer lub wyższa)</li><li>Prędkość internetu-Ściąganie 10mb</li></ul>"
    },
    column_3: {
      title: "",
      content:
        "Jeśli komputer nie spełnia minimalnej specyfikacji, niestety nie będzie można wyświetlić tego doświadczenia."
    }
  }
};
