export default {
  tld: "NL",
  ish: "BN",
  general: {
    privacy: "Privacybeleid",
    direct_marketing: "Direct Marketing",
    transfer_outside: "overdracht buiten de EER",
    copyright: "Copyright © 2021 {companyName}. All Rights Reserved.",
    powered_by:
      'This Virtual Event is powered by&nbsp;<a href="https://www.reydar.com/ar-solutions/virtual-events/" target="_blank">REYDAR</a>',
    cookie_popup: {
      title: "LG.COM Cookies",
      content:
        "Nous utilisons des cookies afin de vous proposer une expérience de navigation web fluide, de vous suggérer des publicités personnalisés ou dans le cadre de l’amélioration des fonctions proposées grâce à l’analyse de trafic. En cliquant sur « TOUT ACCEPTER », vous acceptez notre utilisation des cookies.Pour en savoir plus, consultez notre Politique de confidentialité.",
      settings: {
        button: {
          label: "Cookie-instellingen"
        }
      },
      accept: {
        button: {
          label: "Accepteer alles"
        }
      }
    }
  },
  home: {
    cta: {
      content: "met LG Air Solution Your Loyal Guide",
      button: {
        label: "Gratis toegang tot ISH Digital"
      }
    },
    column1: {
      title: "LG Air Solution virtuele stand",
      content:
        "Dit virtuele evenement is een zeer meeslepende 3D-ervaring. Voor optimale prestaties, adviseren wij een bezoek vanaf een computer die voldoet aan de aanbevolen specificaties.",
      button: {
        label: "Aanbevolen specificaties"
      }
    },
    promo: {
      button: {
        label: "Betreed het Virtuele Evenement"
      }
    }
  },
  specs: {
    column_1: {
      title: "Aanbevolen Systeem Specs:",
      content:
        "<ul><li>64-bits besturingssysteem</li><li>8 GB RAM of hoger</li><li>Speciale NVIDIA / AMD GPU (Geforce 400 of Radeon HD 7000 serie of hoger)</li><li>Quad-core Intel / AMD CPU (Intel Sandy Bridge of AMD Bulldozer serie of hoger)</li><li>Internet snelheid-10mb Downloaden</li></ul>"
    },
    column_2: {
      title: "Minimale Systeemspecificaties:",
      content:
        "<ul><li>64-bits besturingssysteem</li><li>8 GB RAM of hoger</li><li>Speciale NVIDIA / AMD GPU (Geforce 400 of Radeon HD 7000 serie of hoger)</li><li>Quad-core Intel / AMD CPU (Intel Sandy Bridge of AMD Bulldozer serie of hoger)</li><li>Internet snelheid-10mb Downloaden</li></ul>"
    },
    column_3: {
      title: "",
      content:
        "Als uw computer niet aan de minimale specificaties voldoet, kunt u deze ervaring helaas niet bekijken."
    }
  }
};
